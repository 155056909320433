import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import { getSearchResults } from '../api/search'
import { navigate } from 'gatsby'

const Search = () => {
    const [search, setSearch] = useState(null)
    const [filter, setFilter] = useState(null)
    const [checkboxes, setCheckboxes] = useState({
        'contacts': true,
        'directories': true,
        'events': true,
        'pages': true,
        'pois': true,
        'posts': true,
        'services': true,
        'documents': true,
    })
    const [contacts, setContacts] = useState(null)
    const [directories, setDirectories] = useState(null)
    const [events, setEvents] = useState(null)
    const [pages, setPages] = useState(null)
    const [pois, setPois] = useState(null)
    const [posts, setPosts] = useState(null)
    const [services, setServices] = useState(null)
    const [documents, setDocuments] = useState(null)

    /* Esto se puede meter directamente en el search haciendo un array de contacts, ... */
    var index = 0;

    const handleChange = (e) => {
        setSearch(e.target.value)
    }

    /* const handleSubmit = () => {
        setFilter(search)
    } */
    
    const handleCheckboxChange = (e) => {
        setCheckboxes({ ...checkboxes, [e.target.name]: e.target.checked })
    }

    useEffect(() => {
        (async () => {
            if (search) {
                try {
                    setContacts(null)
                    setDirectories(null)
                    setEvents(null)
                    setPages(null)
                    setPosts(null)
                    setPois(null)
                    setServices(null)
                    setDocuments(null)
                    index = 0
                    const data = await getSearchResults(search)
                    setContacts(checkboxes['contacts'] ? data.contacts : null)
                    setDirectories(checkboxes['directories'] ? data.directories : null)
                    setEvents(checkboxes['events'] ? data.events : null)
                    setPages(checkboxes['pages'] ? data.pages : null)
                    setPois(checkboxes['pois'] ? data.pois : null)
                    setPosts(checkboxes['posts'] ? data.posts : null)
                    setServices(checkboxes['services'] ? data.services : null)
                    setDocuments(checkboxes['documents'] ? data.documents : null)

                } catch (error) {
                    setContacts(null)
                    setDirectories(null)
                    setEvents(null)
                    setPages(null)
                    setPosts(null)
                    setPois(null)
                    setServices(null)
                    setDocuments(null)
                    index = 0
                    navigate('/404')
                }
            }else{
                setContacts(null)
                setDirectories(null)
                setEvents(null)
                setPages(null)
                setPosts(null)
                setPois(null)
                setServices(null)
                setDocuments(null)
                index = 0
            }
        })()
    }, [search, checkboxes])

    return (
        <Layout title="Buscador">
            <PageBanner
                pageTitle='Buscador'
                homePageText='Inicio'
                homePageUrl='/'
                activePageText='Buscador'
            />
            <section className='page-area ptb-50'>
                <div className='container'>
                    <div className="search-box">
                        <input type="text" className="form-control" placeholder="Buscar" onChange={handleChange} />
                        {/* <button onClick={handleSubmit}>
                            <i className="flaticon-loupe"></i>
                        </button> */}
                    </div>

                    <div className="row mt-3">
                        <div className="col-lg-12">
                            <input type={'checkbox'} id={'search-pages'} name='pages' onChange={handleCheckboxChange} checked={checkboxes['pages']} />
                            <label htmlFor={'search-pages'} className='ms-2'>Páginas</label>
                            <input type={'checkbox'} id={'search-posts'} className='ms-3' name='posts' onChange={handleCheckboxChange} checked={checkboxes['posts']} />
                            <label htmlFor={'search-posts'} className='ms-2'>Noticias</label>
                            <input type={'checkbox'} id={'search-events'} className='ms-3' name='events' onChange={handleCheckboxChange} checked={checkboxes['events']} />
                            <label htmlFor={'search-events'} className='ms-2'>Eventos</label>
                            <input type={'checkbox'} id={'search-services'} className='ms-3' name='services' onChange={handleCheckboxChange} checked={checkboxes['services']} />
                            <label htmlFor={'search-services'} className='ms-2'>Servicios</label>
                            <input type={'checkbox'} id={'search-pois'} className='ms-3' name='pois' onChange={handleCheckboxChange} checked={checkboxes['pois']} />
                            <label htmlFor={'search-pois'} className='ms-2'>Puntos de Interés</label>
                            <input type={'checkbox'} id={'search-directories'} className='ms-3' name='directories' onChange={handleCheckboxChange} checked={checkboxes['directories']} />
                            <label htmlFor={'search-directories'} className='ms-2'>Directorios</label>
                            <input type={'checkbox'} id={'search-contacts'} className='ms-3' name='contacts' onChange={handleCheckboxChange} checked={checkboxes['contacts']} />
                            <label htmlFor={'search-contacts'} className='ms-2'>Contactos</label>
                            <input type={'checkbox'} id={'search-documents'} className='ms-3' name='documents' onChange={handleCheckboxChange} checked={checkboxes['documents']} />
                            <label htmlFor={'search-documents'} className='ms-2'>Documentos</label>
                        </div>
                    </div>

                    <div className='row mt-3'>
                        {pages && checkboxes['pages'] && pages.length > 0 ?
                            <div className='col-12'>
                                {pages.map((page) => {
                                    index = index + 1
                                    return (
                                        <div key={`page-${page.id}`}>
                                            <hr />
                                            <a href={`/page?slug=${page.slug}`}><h5 className='card-title'>{`${index}. ${page.title}`}</h5></a>
                                            <p>{page.text1 ? page.text1.substr(0, 100).replace(/(<([^>]+)>)/gi, "") + '...' : ''}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            : null
                        }
                        {posts && checkboxes['posts'] && posts.length > 0 ?
                            <div className='col-12'>
                                {posts.map((post) => {
                                    index = index + 1
                                    return (
                                        <div key={`post-${post.id}`}>
                                            <hr />
                                            <a href={`/blog-details?slug=${post.slug}`}><h5 className='card-title'>{`${index}. ${post.title}`}</h5></a>
                                            <p>{post.summary ? post.summary.substr(0, 100).replace(/(<([^>]+)>)/gi, "") + '...' : ''}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            : null
                        }
                        {events && checkboxes['events'] && events.length > 0 ?
                            <div className='col-12'>
                                {events.map((event) => {
                                    index = index + 1
                                    return (
                                        <div key={`event-${event.id}`}>
                                            <hr />
                                            <a href={`/blog-details?slug=${event.slug}`}><h5 className='card-title'>{`${index}. ${event.title}`}</h5></a>
                                            <p>{event.summary ? event.summary.substr(0, 100).replace(/(<([^>]+)>)/gi, "") + '...' : ''}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            : null
                        }
                        {pois && checkboxes['pois'] && pois.length > 0 ?
                            <div className='col-12'>
                                {pois.map((poi) => {
                                    index = index + 1
                                    return (
                                        <div key={`poi-${poi.id}`}>
                                            <hr />
                                            <a href={`/poi-details?slug=${poi.slug}`}><h5 className='card-title'>{`${index}. ${poi.name}`}</h5></a>
                                            <p>{poi.summary ? poi.summary.substr(0, 100).replace(/(<([^>]+)>)/gi, "") + '...' : ''}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            : null
                        }
                        {contacts && checkboxes['contacts'] && contacts.length > 0 ?
                            <div className='col-12'>
                                {contacts.map((contact) => {
                                    let name = contact.last_name ? contact.last_name + ', ' + contact.first_name : contact.first_name
                                    index = index + 1
                                    return (
                                        <div key={`contact-${contact.id}`}>
                                            <hr />
                                            <a href={`/contact?slug=${contact.slug}`}><h5 className='card-title'>{`${index}. ${name}`}</h5></a>
                                            <p>{contact.short_description ? contact.short_description.substr(0, 100).replace(/(<([^>]+)>)/gi, "") + '...' : ''}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            : null
                        }
                        {services && checkboxes['services'] && services.length > 0 ?
                            <div className='col-12'>
                                {services.map((service) => {
                                    index = index + 1
                                    return (
                                        <div key={`service-${service.id}`}>
                                            <hr />
                                            <a href={`/service-details?slug=${service.slug}`}><h5 className='card-title'>{`${index}. ${service.name}`}</h5></a>
                                            <p>{service.summary ? service.summary.substr(0, 100).replace(/(<([^>]+)>)/gi, "") + '...' : ''}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            : null
                        }
                        {directories && checkboxes['directories'] && directories.length > 0 ?
                            <div className='col-12'>
                                {directories.map((directory) => {
                                    index = index + 1
                                    return (
                                        <div key={`directory-${directory.id}`}>
                                            <hr />
                                            <a href={`/directory?slug=${directory.slug}`}><h5 className='card-title'>{`${index}. ${directory.name}`}</h5></a>
                                            <p>{directory.description ? directory.description.substr(0, 100).replace(/(<([^>]+)>)/gi, "") + '...' : ''}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            : null
                        }
                         {documents && checkboxes['documents'] && documents.length > 0 ?
                            <div className='col-12'>
                                {documents.map((document) => {
                                    index = index + 1
                                    return (document.categories.length >0 &&
                                        <div key={`document-${document.id}`}>
                                            <hr />
                                            <a href={`/documents?slug=${document.categories[0].slug}`}><h5 className='card-title'>{`${index}. ${document.name}`}</h5></a>
                                            <p>{document.description ? document.description.substr(0, 100).replace(/(<([^>]+)>)/gi, "") + '...' : ''}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            : null
                        }
                        {filter && pages && pages.length === 0 && posts && posts.length === 0 && events && events.length === 0  && pois && pois.length === 0 && contacts && contacts.length === 0 && services && services.length === 0 && directories && directories.length === 0 && documents && documents.length === 0 ?
                            <div className='col-12'>
                                <h3>No se encontraron resultados</h3>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default Search
